import React from "react"
import Image5 from "../imageComponents/Image5"
import { Link } from "gatsby"

const TastingRoomCard = ({ data }) => {
  return (
    <div className="col-span-full rounded-2xl overflow-hidden bg-white shadow-xl">
      <Link to="/wenatchee">
        <div className="lg:flex">
          <div className="lg:w-3/5">
            <Image5 />
          </div>

          <div className="p-5 mx-auto my-auto">
            <h2 className="font-extrabold tracking-tight text-gray-900 text-4xl">
              Taste
            </h2>
            <br />
            <h2 className="font-bold tracking-tight text-gray-900 text-2xl">
              Now open at Off the Hill in Wenatchee
            </h2>
            <br />
            {/* <h2 className="text-lg text-indigo-600 font-semibold ">
              Stay tuned for updates
            </h2> */}

            <br />
            <Link
              to="/wenatchee"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Tasting Room
            </Link>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default TastingRoomCard
